import React from "react";
import styled from "styled-components";
import BreakpointDown from "../../components/Media/BreakpointDown";
import Img from "gatsby-image";
import { Link} from 'gatsby'
import {
  Section,
  SectionTitle,
  SectionVerticalSubtitle,
  DotGradiantCircle,
  FloatingBox,
} from "../../components/Section";
import {ArrowLineIcon, CheckIcon } from "../../components/Icons";
import SecondaryButton from "../../components/Button/SecondaryButton";

const SectionVerticalSubtitleRight = styled(SectionVerticalSubtitle)`
  transform: translateY(-50%) rotate(90deg);
`;

const PaymentDetails = styled.div`
  display: flex;
  flex-wrap: wrap;
  /* align-items: center; */
  margin-left: -15px;
  margin-right: -15px;
  ${BreakpointDown.lg`
    flex-direction:column-reverse;
    text-align:center;
  `}
ul{
  margin-top: 30px;
  margin-bottom: 30px;
  ${BreakpointDown.xl`
   margin-left:auto;
   margin-right:auto;
   max-width: 549px;
   text-align:left
  `}
}
`;
const PaymentDetailsContent = styled.div`
  flex: 0 0 50%;
  max-width: 50%;
  padding-left: 15px;
  padding-right: 15px;
  ${BreakpointDown.lg`
     flex: 0 0 100%;
     max-width: 100%;
  `}
`;
const PaymentDetailsImg = styled.div`
  flex: 0 0 50%;
  max-width: 50%;
  padding-left: 15px;
  padding-right: 15px;
  position: relative;
  ${BreakpointDown.lg`
    flex: 0 0 100%;
    max-width: 100%;
    width:100%;
    padding-left:15px;
    padding-right:15px;
    margin-bottom:30px;
  `}
`;
const InnerContent = styled.div`
  width: 100%;
  max-width: 575px;
  padding-left: 40px;
  position: relative;
  z-index: 2;
  margin-left: auto;
  ${BreakpointDown.lg`
     margin-right:auto;
   `}
`;
const ImgWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 2;
  .floating-box{
    ${BreakpointDown.sm`
      display:none;
    `}
  }
`;


const DotGradiantCircleLeft = styled(DotGradiantCircle)`
  left: -47px;
  right: auto;
  top: 81px;
`;

const DotGradiantCircleBottom = styled(DotGradiantCircle)`
  right: 0;
  left: 0;
  top: auto;
  margin-left: auto;
  margin-right: auto;
  bottom: -44px;
`;
const CardWrapper = styled.div`
  max-width: 269px;
  width: 100%;
  border-radius: 8px 20px 8px 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  position: absolute;
  left: -50px;
  bottom: -42px;
  z-index: 3;
  @media (max-width: 1366px) {
    left: 0px;
  }
  ${BreakpointDown.lg`
   left: -15px;
   bottom: 36px;
  `}
  ${BreakpointDown.sm`
    left: -15px;
    bottom: 10px;
  `}
`;
const CardInner = styled.div`
  background: #ffffff;
  padding: 30px;
  border: 1px solid #cbd1e2;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  @media (max-width: 1366px) {
    padding: 20px;
  }

  ${BreakpointDown.lg`
    padding: 10px 15px;
  `}
`;
const CardTitle = styled.div`
  font-size: 20px;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  a{
    color:#121316;
  }
`;
const CardImg = styled.div`
  width: 53px;
  height: 53px;
  border-radius: 100px;
  background: #f36224;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  position: relative;
  ${BreakpointDown.sm`
    width: 30px;
    height: 30px;
    margin-bottom:10px;
  `}
  &:before {
      content: "";
      display: block;
      width: 76px;
      height: 76px;
      border-radius: 100px;
      background: rgba(243, 98, 36, 0.14);
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    ${BreakpointDown.sm`
      width: 40px;
      height: 40px;
  `}
  }
  svg {
    fill: #f36224;
    width: 20px;
    height: 20px;
  }
`;
const CardText = styled.div`
   font-size:16px;
   line-height: 28px;
   ${BreakpointDown.sm`
    line-height: 16px;
   `}
`;

function OnlinePayment({ secondOptionSection }) {
  return (
    <Section pt="108px" pb="78px" xpt="60px" xpb="40px">
      <div className="container">
        <SectionVerticalSubtitleRight
          left="auto"
          right="0"
          ml="0"
          xxmr="-130px"
          mr="-300px"
        >
          {secondOptionSection.buttonText}
        </SectionVerticalSubtitleRight>
        <PaymentDetails>
          <PaymentDetailsContent>
            <SectionTitle textAlign="left" lgTextAlign="center" mb="10px">
              {secondOptionSection.headingText}
            </SectionTitle>
            <div
              dangerouslySetInnerHTML={{
                __html:
                  secondOptionSection.contentText.childMarkdownRemark.html,
              }}
            />
            <a href={secondOptionSection.buttonUrl}>
              <SecondaryButton
                text={secondOptionSection.buttonText}
                icon={<ArrowLineIcon />}
              />
            </a>
          </PaymentDetailsContent>
          <PaymentDetailsImg>
            <InnerContent>
              <CardWrapper>
                <CardInner>
                  <CardImg>
                    <CheckIcon />
                  </CardImg>
    
                  <CardTitle><a href={`tel:secondOptionSection.buttonUrl`}>{secondOptionSection.labelText}</a></CardTitle>
                  <CardText>{secondOptionSection.subHeading}</CardText> 
                </CardInner>
              </CardWrapper>
              <ImgWrapper>
                <FloatingBox className="floating-box"
                  right="30px"
                  top="98px"
                  xlRight="16px"
                  xlTop="102px"
                >
                  {/* <CallIcon /> */}
                  <img
                    src={secondOptionSection.backgroundImage.file.url}
                    alt={secondOptionSection.backgroundImage.title}
                  />
                </FloatingBox>
                <DotGradiantCircleLeft width="140px" height="140px" />
                <DotGradiantCircleBottom width="140px" height="140px" />
                <Img
                  fluid={secondOptionSection.sectionImage.fluid}
                  alt={secondOptionSection.sectionImage.title}
                />
              </ImgWrapper>
            </InnerContent>
          </PaymentDetailsImg>
        </PaymentDetails>
      </div>
    </Section>
  );
}
export default OnlinePayment;
