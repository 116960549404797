import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import { Link, graphql } from "gatsby";
import styled from "styled-components";
import BreakpointDown from "../components/Media/BreakpointDown";
import {
  Section,
  BreadCrumb,
  GradientWrapper,
  MarkdownContent,
  MarkdownContentWrapper,
  MarkdownContentWrapperInner,
  SectionPageTitle,
} from "../components/Section";
import { HomeIcon, ArrowRight } from "../components/Icons";
import OnlinePayment from "../sections/custom-payment/online-payment";
import MakePayment from "../sections/custom-payment/make-payment";
import PostalPayment from "../sections/custom-payment/postal-payment";
import checkBlue from "../images/check-blue.svg";

const GetStartedWrapper = styled.div`
  display: flex;
`;

const GetStartedWrapperItem = styled.div`
  max-width: 1000px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
`;

const PaymentCardWapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
  position: relative;
  z-index: 2;
  justify-content: center;
  ${BreakpointDown.sm`
    margin-left: -5px;
  margin-right: -5px;
  `}
`;
const PaymentCardItem = styled.div`
  flex: 0 0 25%;
  max-width: 25%;
  padding-left: 15px;
  padding-right: 15px;
  ${BreakpointDown.xl`
    flex: 0 0 33.3333%;
    max-width: 33.3333%;
  `}
  ${BreakpointDown.sm`
     flex: 0 0 50%;
     max-width: 50%;
     margin-bottom:10px;
     padding-left: 5px;
     padding-right: 5px;
  `}
`;
const PaymentCardSection = styled(Section)`
  margin-top: -74px;
`;

const Card = styled.div`
  background: #ffffff;
  box-shadow: 0px 10px 50px #0000000f;
  border: 1px solid #cbd1e2;
  border-radius: 5px;
  position: relative;
  padding: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 5px;
  height: 100%;
  @media (max-width: 1440px) {
    padding: 20px;
  }
  ${BreakpointDown.xl`
    padding:15px;
  `}

  &:before {
    position: absolute;
    left: 5px;
    top: 5px;
    content: "";
    width: 0;
    height: 0;
    border-top: 20px solid #ebf0ff;
    border-right: 20px solid transparent;
    border-bottom: 20px solid transparent;
    border-left: 20px solid #ebf0ff;
  }
`;
const Icon = styled.div`
  width: 54px;
  height: 54px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 5px;
  svg {
    width: 100%;
  }
`;
const Title = styled.div`
  color: #121316;
  font-size: 19px;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  text-align: center;
  margin-top: 5px;
  @media (max-width: 1440px) {
    font-size: 18px;
    line-height: 24px;
  }
`;

function CustomPaymentPage(queryData) {
  let pageData = queryData.data.contentfulPaymentOptionPage;

  return (
    <Layout pageName="customer-payment-options">
      <SEO
        title={pageData.metaTitle}
        description={pageData.metaDescription.metaDescription}
      />
      <Section
        bgColor="rgba(203, 209, 226, 0.55)"
        pt="180px"
        xpt="60px"
        pb="111px"
      >
        <BreadCrumb top="75" xtop="15px">
          <div className="container">
            <Link to="/">
              <HomeIcon /> <ArrowRight />
              </Link>
            <Link className="line-after-heading">
            Payment Options
            </Link>
          </div>
        </BreadCrumb>
        <div className="container">
          <GetStartedWrapper>
            <GetStartedWrapperItem>
              <SectionPageTitle
                className="h2"
                textAlign="center"
                mt="20px"
                ml="0"
                mb="15px"
              >
                {pageData.paymentFirstSection.headingText}
              </SectionPageTitle>
              <div
                dangerouslySetInnerHTML={{
                  __html:
                    pageData.paymentFirstSection.contentText.childMarkdownRemark
                      .html,
                }}
              />
            </GetStartedWrapperItem>
          </GetStartedWrapper>
        </div>
      </Section>
      <PaymentCardSection bgColor="transpasrent" className="gradinat-wrapper">
        <div className="container">
          <PaymentCardWapper>
            {pageData.paymentFirstSection.childrenContentfulSectionFeatureDetailsJsonNode.map(
              (item) => {
                return (
                  <PaymentCardItem>
                    <Card>
                      <Icon>
                        <img src={item.optionImage} alt={item.optionName} />
                      </Icon>
                      <Title>{item.optionName}</Title>
                    </Card>
                  </PaymentCardItem>
                );
              }
            )}
          </PaymentCardWapper>
        </div>
      </PaymentCardSection>
      <GradientWrapper>
        <OnlinePayment firstOptionSection={pageData.firstOptionSection} />
      </GradientWrapper>
      <MakePayment secondOptionSection={pageData.secondOptionSection} />
      <PostalPayment thirdOptionSection={pageData.thirdOptionSection} />
      <Section
        pt="100px"
        pb="177px"
        xpt="60px"
        xpb="100px"
        bdrTop="#CBD1E2"
        bgColor="transparent"
      >
        <div className="container">
          <MarkdownContent bg={checkBlue}>
            <MarkdownContentWrapper>
              <MarkdownContentWrapperInner>
                <div
                  dangerouslySetInnerHTML={{
                    __html: pageData.contentSection.childMarkdownRemark.html,
                  }}
                />
              </MarkdownContentWrapperInner>
            </MarkdownContentWrapper>
          </MarkdownContent>
        </div>
      </Section>
    </Layout>
  );
}

export default CustomPaymentPage;

export const query = graphql`
  query PaymentOptionPage {
    contentfulPaymentOptionPage {
      metaTitle
      metaDescription {
        metaDescription
      }
      pageUrl
      paymentFirstSection {
        headingText
        contentText {
          childMarkdownRemark {
            html
          }
        }
        childrenContentfulSectionFeatureDetailsJsonNode {
          optionName
          optionImage
        }
      }
      firstOptionSection {
        labelText
        subHeading
        headingText
        contentText {
          childMarkdownRemark {
            html
          }
        }
        backgroundImage {
          title
          file {
            url
          }
        }
        buttonText
        buttonUrl
        sectionImage {
          title
          fluid {
            aspectRatio
            base64
            src
            srcSet
            srcSetWebp
            srcWebp
          }
        }
      }
      secondOptionSection {
        labelText
        subHeading
        headingText
        contentText {
          childMarkdownRemark {
            html
          }
        }
        backgroundImage {
          title
          file {
            url
          }
        }
        buttonText
        buttonUrl
        sectionImage {
          title
          fluid {
            aspectRatio
            base64
            src
            srcSet
            srcSetWebp
            srcWebp
          }
        }
      }
      thirdOptionSection {
        labelText
        subHeading
        headingText
        contentText {
          childMarkdownRemark {
            html
          }
        }
        backgroundImage {
          title
          file {
            url
          }
        }
        buttonText
        buttonUrl
        sectionImage {
          title
          fluid {
            aspectRatio
            base64
            src
            srcSet
            srcSetWebp
            srcWebp
          }
        }
      }
      contentSection {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`;
