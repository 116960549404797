import React from "react";
import styled from "styled-components";
import BreakpointDown from "../../components/Media/BreakpointDown";
import Img from "gatsby-image";
import {
  Section,
  SectionTitle,
  SectionVerticalSubtitle,
  DotGradiantCircle,
  FloatingBox,
} from "../../components/Section";
import {LogoSymbolIcon } from "../../components/Icons";

const PaymentDetails = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  flex-direction: row-reverse;
  ${BreakpointDown.lg`
    flex-direction:column-reverse;
    text-align:center;
  `}
  ul{
  margin-top: 30px;
  margin-bottom: 30px;
  ${BreakpointDown.xl`
   margin-left:auto;
   margin-right:auto;
   max-width: 549px;
   text-align:left;
  `}
}
`;
const PaymentDetailsContent = styled.div`
  flex: 0 0 60%;
  max-width: 60%;
  ${BreakpointDown.lg`
      flex: 0 0 100%;
      max-width: 100%;
    `}
`;
const PaymentDetailsImg = styled.div`
  flex: 0 0 40%;
  max-width: 40%;
  position: relative;
  ${BreakpointDown.lg`
      flex: 0 0 100%;
      max-width: 100%;
      width:100%;
      margin-bottom:30px;
    `}
`;
const InnerContent = styled.div`
  width: 100%;
  max-width: 368px;
  position: relative;
  z-index: 2;
  margin-right: auto;
  span{
    min-height:30px;
    line-height:1;
    display:flex;
    align-items:center;
  }
  ${BreakpointDown.xl`
    margin-left:auto;
    margn-right:auto;
   `}
`;
const ImgWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 2;
`;

const DotGradiantCircleLeft = styled(DotGradiantCircle)`
  left: -9px;
  right: auto;
  top: -40px;
`;

const DotGradiantCircleBottom = styled(DotGradiantCircle)`
  right: 0;
  left: 0;
  top: auto;
  margin-left: auto;
  margin-right: auto;
  bottom: -42px;
`;

const MailingAddress = styled.div`
  padding: 20px 44px;
  background: #ebf0ff;
  border: 1px solid #cbd1e2;
  border-radius: 40px 5px;
  ${BreakpointDown.lg`
    padding:20px 25px;
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;
  `}
`;

const MailingAddressItem = styled.div`
  display: flex;
  align-items: center;
  text-align: left;
`;
const LogoWrapper = styled.div`
  width: 70px;
  margin-right: 18px;
  align-self: flex-end;
  svg {
    max-width: 100%;
  }
`;
const ContentWrapper = styled.div`
  font-size: 24px;
  font-weight: 600;
  line-height: 34px;
  font-family: "Poppins", sans-serif;
  color: #223c7e;
  ${BreakpointDown.xl`
    font-size:20px;
    line-height:28px;
  `}
  ${BreakpointDown.lg`
    font-size:16px;
    line-height:22px;
  `}
`;
const MailingAddressHeading = styled.h5`
  font-size: 20px;
  color: #121316;
`;
function PostalPayment({ thirdOptionSection }) {
  return (
    <Section pt="108px" pb="78px" xpt="60px" bdrTop="#CBD1E2" xpb="40px">
      <div className="container">
        <SectionVerticalSubtitle
          left="0"
          right="auto"
          xxml="-100px"
          ml="-200px"
        >
          {thirdOptionSection.buttonText}
        </SectionVerticalSubtitle>
        <PaymentDetails>
          <PaymentDetailsContent>
            <SectionTitle textAlign="left" lgTextAlign="center" mb="10px">
              {thirdOptionSection.headingText}
            </SectionTitle>

            <div
              dangerouslySetInnerHTML={{
                __html: thirdOptionSection.contentText.childMarkdownRemark.html,
              }}
            />

            <MailingAddress>
              <MailingAddressHeading className="line-after-heading">
                Mailing Address
              </MailingAddressHeading>
              <MailingAddressItem>
                <LogoWrapper>
                  <LogoSymbolIcon />
                </LogoWrapper>
                <ContentWrapper>
                  {" "}
                  {thirdOptionSection.labelText} <br />{" "}
                  {thirdOptionSection.subHeading}
                </ContentWrapper>
              </MailingAddressItem>
            </MailingAddress>
          </PaymentDetailsContent>
          <PaymentDetailsImg>
            <InnerContent>
              <FloatingBox right="-60px" top="20px" xlRight="-10px">
                <span>{thirdOptionSection.subHeading}</span>
              </FloatingBox>
              <FloatingBox left="30px" bottom="20px">
                <img
                  src={thirdOptionSection.backgroundImage.file.url}
                  alt={thirdOptionSection.backgroundImage.title}
                />
              </FloatingBox>
              <DotGradiantCircleLeft width="140px" height="140px" />
              <DotGradiantCircleBottom width="140px" height="140px" />
              <ImgWrapper>
                <Img
                  fluid={thirdOptionSection.sectionImage.fluid}
                  alt={thirdOptionSection.sectionImage.title}
                />
              </ImgWrapper>
            </InnerContent>
          </PaymentDetailsImg>
        </PaymentDetails>
      </div>
    </Section>
  );
}
export default PostalPayment;
