import React from "react";
import styled from "styled-components";
import Img from "gatsby-image";
import BreakpointDown from "../../components/Media/BreakpointDown";
import {
  Section,
  SectionTitle,
  SectionVerticalSubtitle,
  DotGradiantCircle,
  FloatingBox,
} from "../../components/Section";
import {ArrowLineIcon, CheckIcon } from "../../components/Icons";
import SecondaryButton from "../../components/Button/SecondaryButton";

const PaymentDetails = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-left: -15px;
  margin-right: -15px;
  ${BreakpointDown.lg`
    text-align:center;
  `}
  ul{
  margin-top: 30px;
  margin-bottom: 30px;
  ${BreakpointDown.xl`
   margin-left:auto;
   margin-right:auto;
   max-width: 549px;
   text-align:left
  `}
}
`;
const PaymentDetailsContent = styled.div`
  flex: 0 0 55%;
  max-width: 55%;
  padding-left: 15px;
  padding-right: 15px;
  ${BreakpointDown.lg`
    flex: 0 0 100%;
    max-width: 100%;
  `}
`;
const PaymentDetailsImg = styled.div`
  flex: 0 0 45%;
  max-width: 45%;
  padding-left: 15px;
  padding-right: 15px;
  position: relative;
  ${BreakpointDown.lg`
    flex: 0 0 100%;
    max-width: 100%;
    margin-bottom:30px;
  `}
`;
const InnerContent = styled.div`
  max-width: 367px;
  width: 100%;
  position: relative;
  z-index: 2;
  ${BreakpointDown.lg`
    margin-left:auto;
    margin-right:auto;
  `}
`;
const ImgWrapper = styled.div`
  width: 100%;
  height: 100%;
  background: #223c7e;
  position: relative;
  z-index: 2;
  padding-top: 45px;
  padding-left: 15px;
`;


const DotGradiantCircleRight = styled(DotGradiantCircle)`
  right: -49px;
  left: auto;
  top: -40px;
`;

const DotGradiantCircleBottom = styled(DotGradiantCircle)`
  right: 0;
  left: 0;
  top: auto;
  margin-left: auto;
  margin-right: auto;
  bottom: -42px;
`;
const CardWrapper = styled.div`
  filter: drop-shadow(0px 1px 1px rgba(50, 50, 0, 0.5));
  max-width: 269px;
  border-radius: 8px 20px 8px 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  position: absolute;
  right: -167px;
  top: 55px;
  z-index: 3;
  @media (max-width: 1366px) {
    right: -132px;
    max-width: 250px;
  }
  ${BreakpointDown.xl`
      right: -52px;
      bottom:20px;
      top:auto;
  `}
  ${BreakpointDown.md`
    right:0px;
  `}
`;
const CardInner = styled.div`
  clip-path: polygon(0 0, 100% 0%, 99% 94%, 0 100%);
  background: #ffffff;
  padding: 15px 15px 30px;
  border: 1px solid #cbd1e2;
  border-radius: 0 20px 0 0;
  ${BreakpointDown.sm`
     padding: 15px 15px 20px;
  `}
`;
const CardTitle = styled.div`
  font-size: 20px;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
`;
const CardImg = styled.div`
  border-radius: 1px solid #cbd1e2;
  width: 53px;
  height: 53px;
  border-radius: 100px;
  background: #41a209;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: -22px;
  position: absolute;
  ${BreakpointDown.sm`
    width: 40px;
    height: 40px;
  `}
  svg {
    color: #fff;
    width: 30px;
    height: 30px;
  }
`;
const CardText = styled.div`
   font-size:16px;
   line-height: 28px;
   ${BreakpointDown.sm`
     line-height: 16px;
   `}
`;

function OnlinePayment({ firstOptionSection }) {
  return (
    <Section pt="101px" pb="100px" xpt="60px" xpb="40px" bgColor="transparent">
      <div className="container">
        <SectionVerticalSubtitle
          left="0"
          right="auto"
          xxml="-100px"
          ml="-200px"
          mr="0"
        >
          {firstOptionSection.buttonText}
        </SectionVerticalSubtitle>
        <PaymentDetails>
          <PaymentDetailsImg>
            <InnerContent>
              <FloatingBox left="15px" top="-22px">
                <img
                  src={firstOptionSection.backgroundImage.file.url}
                  alt={firstOptionSection.backgroundImage.title}
                />
              </FloatingBox>
              <DotGradiantCircleRight width="140px" height="140px" />
              <DotGradiantCircleBottom width="140px" height="140px" />
              <CardWrapper>
                <CardInner>
                  <CardTitle>{firstOptionSection.labelText}</CardTitle>
                  <CardText>{firstOptionSection.subHeading}</CardText>
                </CardInner>
                <CardImg>
                  <CheckIcon />
                </CardImg>
              </CardWrapper>
              <ImgWrapper>
                <Img
                  fluid={firstOptionSection.sectionImage.fluid}
                  alt={firstOptionSection.sectionImage.title}
                />
              </ImgWrapper>
            </InnerContent>
          </PaymentDetailsImg>
          <PaymentDetailsContent>
            <SectionTitle textAlign="left" mb="10px" lgTextAlign="center">
              {firstOptionSection.headingText}
            </SectionTitle>
            <div
              dangerouslySetInnerHTML={{
                __html: firstOptionSection.contentText.childMarkdownRemark.html,
              }}
            />
            <a
              href={firstOptionSection.buttonUrl}
              target="_blank"
              rel="noreferrer"
            >
              <SecondaryButton
                text={firstOptionSection.buttonText}
                icon={<ArrowLineIcon />}
              />
            </a>
          </PaymentDetailsContent>
        </PaymentDetails>
      </div>
    </Section>
  );
}
export default OnlinePayment;
